import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import {
    Link
} from "react-router-dom";
import { isMobile } from 'react-device-detect';

export default class VMenuHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <div style={{ width: "100%", marginTop: 60 }}>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" >
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <div style={{ width: '100%'}}>
                            <Nav className="mr-auto" className="justify-content-center">
                                <div style={{ backgroundColor: 'transparent', width: "17%", alignSelf: 'center'}}>
                                    <Link to="/#catalogue_section">
                                        <Nav.Link href="/#home_section" >
                                            <a style={{ fontSize: 20, fontFamily: 'BikoBold', color: 'white' }} > INICIO </a>
                                        </Nav.Link>
                                    </Link>
                                </div>
                                {!isMobile && <div style={{ backgroundColor: 'transparent'}} >
                                    <Nav.Link >                        
                                        <a style={{fontSize: 20, fontFamily: 'BikoBold', color: 'white'}} > | </a>
                                    </Nav.Link>
                                </div>}
                                <div style={{ backgroundColor: 'transparent', width: "17%", alignSelf: 'center'}}>
                                    <Nav.Link href="/#catalogue_section">
                                        <a style={{ fontSize: 20, fontFamily: 'BikoBold', color: 'white' }} > CATEGORÍAS </a>
                                    </Nav.Link>
                                </div>
                                {!isMobile &&<div style={{ backgroundColor: 'transparent'}} >
                                    <Nav.Link >                        
                                        <a style={{fontSize: 20, fontFamily: 'BikoBold', color: 'white'}} > | </a>
                                    </Nav.Link>
                                </div>}
                                <div  style={{ backgroundColor: 'transparent', width: "17%", alignSelf: 'center'}}>
                                    <Nav.Link href="/#campaign_section">
                                        <a style={{ fontSize: 20, fontFamily: 'BikoBold', color: 'white' }} > CAMPAÑA </a>
                                    </Nav.Link>
                                </div>
                                {!isMobile &&<div style={{ backgroundColor: 'transparent'}} >
                                    <Nav.Link >                        
                                        <a style={{fontSize: 20, fontFamily: 'BikoBold', color: 'white'}} > | </a>
                                    </Nav.Link>
                                </div>}
                                <div style={{ backgroundColor: 'transparent', width: "17%", alignSelf: 'center'}}>
                                    <Nav.Link href="/#voting_section">
                                        <a style={{ fontSize: 20, fontFamily: 'BikoBold', color: 'white' }} > VOTACIÓN </a>
                                    </Nav.Link>
                                </div>
                                {!isMobile && <div style={{ backgroundColor: 'transparent'}} >
                                    <Nav.Link >                        
                                        <a style={{fontSize: 20, fontFamily: 'BikoBold', color: 'white'}} > | </a>
                                    </Nav.Link>
                                </div> }
                                <div style={{ backgroundColor: 'transparent', width: "17%", alignSelf: 'center'}}>
                                    <Nav.Link href="/nosotros">
                                        <a style={{ fontSize: 20, fontFamily: 'BikoBold', color: 'white' }} > NOSOTROS </a>
                                    </Nav.Link>
                                </div>
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}