import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import VActionInput from '../../components/ActionInput/ActionInput.js';
import VModal from '../../components/SignUp/SignUp.js';
import VProfile from '../../components/Profile/Profile.js';
import UserProvider from '../../providers/UserProvider.js';
import UserContext from '../../providers/UserProvider.js';
import Modal from 'react-bootstrap/Modal';
import { db, auth } from '../../services/firebase';
import VProfileImage from '../../components/ProfileImage/ProfileImage.js';
import {
    Link
} from "react-router-dom";
import { isMobile } from 'react-device-detect';

export default class VMainHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSignUp: false,
            user: null,
            errorLogin: "",
            recoverSent: false,
            showProfile: false
        }
    }

    onLogin = (event) => {
        event.preventDefault();
        this.setState({ errorLogin: "" });
        var headerComponent = this;
        auth().languageCode = 'es';
        auth().signInWithEmailAndPassword(event.target.elements.formBasicEmail.value, event.target.elements.formBasicPassword.value).catch(function (error) {
            console.log(error);
            headerComponent.setState({ errorLogin: "Ocurrió un error. Revisa que tu usuario y contraseña sean correctos." });
        });

    }

    componentDidMount = () => {
        this.state.showSignUp = false;
        auth().onAuthStateChanged(userAuth => {
            var user = { authUser: userAuth, dbUser: null };
            if (userAuth != null) {
                this.updateUser(userAuth);
            } else {
                this.props.userCallback(null);
                this.setState({ user: null });
            }
        });
    };

    updateUser = (userAuth) => {
        if (userAuth == null && this.state.user != null) {
            userAuth = this.state.user.authUser;
        }
        if (userAuth == null) {
            return;
        }
        var user = { authUser: userAuth, dbUser: null };
        let userDoc = db.collection('users').doc(userAuth.uid);
        let reactObj = this;
        let getDoc = userDoc.get()
            .then(doc => {
                if (!doc.exists) {
                    //console.log('No document.');
                } else {
                    user.dbUser = doc.data();
                    user.dbUser._id = doc.id;
                    reactObj.props.userCallback(user.dbUser);
                }
                this.setState({ user: user });
            })
            .catch(err => {
                console.log('Error reading user ' + err);
                this.setState({ user: user });
            });
    }

    recoverPasswordConfirmation = () => {
        this.setState({
            showRecoverPasswordConfirmation: true
        });
    }

    recoverPassword = (event) => {
        auth().languageCode = 'es';
        auth().sendPasswordResetEmail(event.target.elements.formBasicEmailRecover.value);
        this.setState({ recoverSent: true });
    }

    render() {

        var userName = ""
        if (this.state.user != null && this.state.user.dbUser != null && this.state.user.dbUser.username != null) {
            console.log("UID", this.state.user.dbUser._id);
            userName = this.state.user.dbUser.username;
            if (userName.length > 10) {
                userName = userName.substring(0, 9);
            }
        }
        let marginLogin = 0;
        if(isMobile){
            marginLogin = -30;
        }
        let marginAccount = -60;
        let marginAccountOutter = 40;
        if(isMobile){
            marginAccount = -25;
            marginAccountOutter = 20;
        }        
        return (
            <div style={{ marginBottom: 0}}>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top" >
                    <Link to="/">
                        <img style={{ maxHeight: 35 }} src={require("./Logo_Mainheader_1024.png")} />
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav style= {{alignItems: 'center', justifyContent: 'space-between'}}>
                            <a href={"/#catalogue_section"}>
                                <VActionInput defaultSearchText="Buscar" buttonText="BUSCAR" onSubmit={()=>{}}/>
                            </a>
                            {this.state.user == null &&
                                <div style={
                                    {
                                        borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16,
                                        marginLeft: 10, color: 'white', alignSelf: 'center', width: 100
                                    }}>
                                    <NavDropdown bg="dark" id="collasible-nav-dropdown"
                                        style={{backgroundColor: 'transparent', marginLeft: marginLogin}}
                                        title={
                                            <a style={{ color: 'white' , marginLeft: marginLogin*-1}}>LOG IN</a>
                                        }
                                        id={'dropdown-button-drop-left'}
                                    >
                                        <div >
                                            <form
                                                onSubmit={this.onLogin}
                                            >
                                                <Form.Group controlId="formBasicEmail" >
                                                    <Form.Label style={{
                                                        marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'
                                                    }}>
                                                        Usuario
                                        </Form.Label>
                                                    <Form.Control type="email" placeholder="Correo electrónico" />
                                                    <Form.Text className="text-muted">
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword" >
                                                    <Form.Label style={{
                                                        marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'
                                                    }}>
                                                        Contraseña
                                        </Form.Label>
                                                    <Form.Control type="password" placeholder="Contraseña" />
                                                </Form.Group>
                                                <Button variant="primary"
                                                    type="submit"
                                                    style={
                                                        {
                                                            borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16,
                                                            marginLeft: 'auto', marginRight: 'auto', marginTop: -8, marginBottom: 'auto', display: 'flex'
                                                        }}
                                                >
                                                    Log in
                                    </Button>                                                
                                            </form >
                                            <a style={styles.revocerPasswordText} href="#" onClick={this.recoverPasswordConfirmation}>
                                                Recuperar contraseña.
                                            </a>
                                            {this.state.errorLogin != "" &&
                                                <p style={styles.errorText}> {this.state.errorLogin} </p>
                                            }
                                        </div>
                                    </NavDropdown>
                                </div>
                            }
                            {this.state.user == null &&
                                <Nav.Link href="#deets">
                                    <Button variant="primary" style={
                                        {
                                            borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16,
                                            
                                        }}
                                        onClick={() => {
                                            console.log("register");
                                            this.setState({ showSignUp: true });
                                        }}>
                                        REGISTRARSE
                            </Button>
                                </Nav.Link>
                            }
                            {this.state.user != null &&
                                <div style={
                                    {
                                        borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16,
                                        marginLeft: 25, color: 'white', flexDirection: 'row', display: 'flex'
                                    }}>
                                    <div style={{ position: 'relative', left: -15 }}>
                                        {this.state.user != null && this.state.user.dbUser != null && this.state.user.dbUser.photoUrl != null &&
                                            <VProfileImage
                                                size='40'
                                                url={this.state.user.dbUser.photoUrl}
                                                ringSize='5'
                                            />
                                        }
                                        {this.state.user == null || this.state.user.dbUser == null || this.state.user.dbUser.photoUrl == null &&
                                            <VProfileImage
                                                size='40'
                                                url=""
                                                ringSize='5'
                                            />
                                        }
                                    </div>
                                    <NavDropdown bg="dark" id="collasible-nav-dropdown" style={{backgroundColor: 'transparent', marginLeft: marginAccount, marginRight: 15}}
                                        title={
                                            <a style={{ color: 'white', marginLeft: marginAccountOutter }}>{userName}</a>
                                        }
                                        id={'dropdown-button-drop-left'}
                                    >
                                        <div >
                                            <Button variant="primary" style={
                                                {
                                                    borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 14,
                                                    marginLeft: 'auto', marginRight: 'auto', marginTop: 10, marginBottom: 'auto', display: 'flex', width: 150, justifyItems: 'center', justifySelf: 'center'
                                                }}
                                                onClick={() => {
                                                    console.log("cuenta");
                                                    this.setState({ showProfile: true });
                                                }}
                                            >
                                                CUENTA
                                    </Button>
                                            <Button variant="primary" style={
                                                {
                                                    borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 14,
                                                    marginLeft: 'auto', marginRight: 'auto', marginTop: 10, marginBottom: 'auto', display: 'flex', width: 150
                                                }}
                                                onClick={() => {
                                                    console.log("Sign Out");
                                                    auth().signOut().then(function () {
                                                        this.props.userCallback(null);
                                                        this.setState({ user: null })
                                                    }).catch(function (error) {
                                                        // An error happened.
                                                    });
                                                    window.location.reload(false);
                                                }}
                                            >
                                                CERRAR SESIÓN
                                    </Button>
                                        </div>
                                    </NavDropdown>
                                </div>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <VModal show={this.state.showSignUp}
                    onClose={() => {
                        this.updateUser(null);
                        this.setState({ showSignUp: false })                        
                    }
                    }
                    onSignUp={this.onSignUp}
                />
                <VProfile
                    show={this.state.showProfile}
                    user={this.state.user}
                    onClose={() => { this.setState({ showProfile: false }) }}
                />
                <Modal show={this.state.showRecoverPasswordConfirmation} onHide={() => { this.setState({ showRecoverPasswordConfirmation: false, recoverSent: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Restablecer contraseña</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!this.state.recoverSent &&
                            <form
                                onSubmit={this.recoverPassword}
                            >
                                <Form.Group controlId="formBasicEmailRecover" >
                                    <Form.Label style={{
                                        marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'
                                    }}>
                                        Correo electrónico
                                </Form.Label>
                                    <Form.Control type="email" placeholder="Correo electrónico" />
                                    <Form.Text className="text-muted">
                                    </Form.Text>
                                </Form.Group>
                                <Button variant="primary"
                                    type="submit"
                                    style={
                                        {
                                            borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16,
                                            marginLeft: 'auto', marginRight: 'auto', marginTop: -8, marginBottom: 'auto', display: 'flex'
                                        }}
                                >
                                    Recuperar contraseña.
                            </Button>
                            </form >
                        }
                        {this.state.recoverSent &&
                            <p> Se envió correo de recuperación de contraseña. </p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { 
                            this.setState({ showRecoverPasswordConfirmation: false, recoverSent: false });                            
                        }}
                            style={
                                {
                                    borderColor: '#7144b7', borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16,
                                    marginLeft: 'auto', marginRight: 'auto', marginTop: -8, marginBottom: 'auto', display: 'flex'
                                }}
                        >
                            Cerrar
                    </Button>
                    </Modal.Footer>
                </Modal>
                <UserProvider />
            </div>
        )
    }
}


const styles = {
    errorText: { color: '#ed0000', fontSize: 15, fontFamily: 'BikoRegular', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'center', alignItems: 'center', display: 'flex' },
    revocerPasswordText: { color: '#ed0000', fontSize: 10, fontFamily: 'BikoRegular', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'center', alignItems: 'center', display: 'flex' },
};