import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    Link
} from "react-router-dom";

export default class VInfoModal extends React.Component {
    
    constructor(props) {
        super(props); 
        var show = false;       
        if ( this.props.show!=null ){
            show = this.props.show;
        }
        this.state = { 
            show: show,            
        }         
    }


    render() {        
      

        return (
            <div>
                <Link onClick={()=>this.setState({show:true})}>
                <img style={{ maxHeight: 35,  marginRight: 10}} src={require("./Boton_Info_64.png")}alt="Third slide"/>
                </Link>
                <Modal show={this.state.show}>

                        {this.props.title!=null &&
                            <p style={{fontSize: 30, fontFamily: 'BikoBold', marginLeft:'auto', marginRight: 'auto',display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'}}> {this.props.title} </p>
                        }
                        {this.props.img!=null &&
                            <img style={{maxWidth: 250, marginLeft: 'auto', marginRight:'auto', display: 'flex'}} src={this.props.img} alt="Third slide"/>
                        }
                        { this.props.description!=null &&
                            <div style={{width:'70%', marginLeft: 'auto', marginRight: 'auto'}}>
                                <p style={{fontSize: 20, fontFamily: 'BikoRegular', marginLeft:'auto', marginRight: 'auto',display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', textAlign: 'center'}}> {this.props.description} </p>
                            </div>
                        }
                    
                        <Button variant="primary" type="submit" style={styles.cancelButton} onClick={()=>{
                        // this.props.show = false;
                            this.setState({show:false})
                        }}>
                                CERRAR
                        </Button>
                </Modal>
            </div>
        )
    }
}

const styles = {
    input:{borderRadius: '15px', borderColor:  '#515151', borderWidth: 2 , fontFamily: 'BikoRegular', marginTop:10, marginBottom:10},
    registerButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 16, marginBottom:10, display: 'flex'},
    buttonGoogle: {borderColor:  '#dd4b39',borderRadius: '0px', backgroundColor: '#dd4b39'
        ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 16, marginBottom:30, display: 'flex'},                     
    modalText: {color:'#3f3f3f', fontSize: 30, fontFamily: 'BikoBold', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
    cancelButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 10, marginBottom:10, display: 'flex'},
    modalText: {color:'#3f3f3f', fontSize: 30, fontFamily: 'BikoBold', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
    errorText: {color:'#ed0000', fontSize: 10, fontFamily: 'BikoRegular', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
};