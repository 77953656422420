import React, { Component, createContext } from "react";
import { auth,db } from "../services/firebase.js";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    user: null
  };

  componentDidMount = () => {
   /* auth().onAuthStateChanged(userAuth => {
      var user={authUser:userAuth,  dbUser:null};
      console.log(userAuth);
      let userDoc = db.collection('users').doc(userAuth.uid);
        let getDoc = userDoc.get()
        .then(doc => {
            if (!doc.exists) {
                console.log('No document.');
            } else {                
                user.dbUser = doc.data();
                console.log("User readed")
                console.log(user.dbUser)                
            }
            this.setState({ user: user});
            this.props.onLogIn();
        })
        .catch(err => {
            console.log('Error reading user ' + err);
            this.setState({ user: user});
        });
      
    });*/
  };
  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
        </UserContext.Provider>
    );
  }
}
export default UserProvider;