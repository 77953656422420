import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import {  db, auth } from '../../services/firebase';

import {
    Link
} from "react-router-dom";
import { isMobile } from 'react-device-detect';


export default class VCarousel extends React.Component {
    
    constructor(props) {
        super(props);                 

        this.state = { 
            toursCollection: []         
        }

        let toursCollection = db.collection('content');
        let toursCollectionMap = toursCollection.get()
          .then(snapshot => {
            console.log( "Snapshot" )
            var fullCollection =[]
            snapshot.forEach(doc => {
                fullCollection.push({id: doc.id, data:doc.data()});
              });
              console.log( "Snapshot finished" )
              console.log( fullCollection)
              this.setState({toursCollection:fullCollection});
          })
          .catch(err => {
            console.log('Error getting documents', err);
          });
    

    }


    render() {
        var bg=require('./temporal0.jpg')
        var titleBG=require('./Titulo_Carrusel_1024.png')
        var shadowBG=require('./Sombra_Carrusel_1024.png')
        
        let corrousel;
		if (!isMobile) { corrousel = 
            <Carousel>
            {this.state.toursCollection.map( tour => {
                if(tour.data.published == null || tour.data.published == true){
                return (
                        <Carousel.Item>
                            <Link to={ "/vp/" + tour.id } >
                                <div style={{ height:'auto', width: '100%', marginTop: 0, flexDirection: "column",  backgroundColor: 'transparent' }}>
                                    <img
                                    className="d-block w-100"
                                    src={tour.data.bannerUrl}
                                    alt="Third slide"
                                    style= {{minHeight: 450}}
                                    />
                                    <div style={{position: 'absolute', left: -5 , top:50, backgroundImage: "url("+titleBG+")" , backgroundSize: 'cover' , width: 500, height: 130, backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <a style={{fontFamily: 'BikoRegular', fontSize: 38, color: 'white', backgroundColor: 'transparent'}}>  
                                                <u>{tour.data.name}</u>
                                            </a>
                                    </div>         
                                    <div style={{position: 'absolute', right: -5 , top:0, backgroundImage: "url("+shadowBG+")" , backgroundSize: 'contain' , width: "60%", height: 800 , backgroundColor: 'transparent'}}>
                                        <div style={{width: "70%", marginRight:50, marginLeft: 'auto', marginTop: 100, marginBottom: 'auto', display: 'flex'}}>
                                            <a style={{fontFamily: 'BikoRegular', fontSize: 24, color: 'white', marginTop: 'auto', marginBottom: 'auto', textAlign: 'end'}}>  
                                                {tour.data.description}
                                            </a>
                                        </div>
                                    </div>
                                </div>   
                            </Link>
                        </Carousel.Item>
                    );
                }
             })
            }                    
            </Carousel>
		} else { corrousel = 
            <Carousel>
            {this.state.toursCollection.map( tour => {
                if(tour.data.published == null || tour.data.published == true){
                    return (
                        <Carousel.Item>
                            <Link to={ "/vp/" + tour.id } >
                                <div style={{ backgroundColor: 'transparent' }}>
                                    <img
                                    className="d-block w-100"
                                    src={tour.data.bannerUrl}
                                    alt="Third slide"
                                    />
                                    
                                    <div style={{ backgroundColor: 'transparent' }}>
                                            <a style={{fontFamily: 'BikoRegular', fontSize: 20, color: '#ea2f64'}}>  
                                                <u>{tour.data.name}</u>
                                            </a>
                                    </div>                            
                                    
                                    <div style={{backgroundColor: 'transparent', minHeight: 230}}>
                                        <div style={{}}>
                                            <a style={{fontFamily: 'BikoRegular', fontSize: 15, color: 'white'}}>  
                                                {tour.data.description}
                                            </a>
                                        </div>
                                    </div>
                                </div>   
                            </Link>
                        </Carousel.Item>
                    );
                }
             })
            }                    
            </Carousel>
		}

        return (
            <div>
                {corrousel}
            </div>
        )
    }
}