import React from 'react';
import './App.css';
import VMain from './sections/Main/Main.js';


function App() {

  return (
    <VMain />
  );
}

export default App;
