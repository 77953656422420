import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { isMobile } from 'react-device-detect';

export default class VActionInput extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }

    onSubmit =(event) =>{
        event.preventDefault();
        this.props.onSubmit(event.target.elements.formInput.value);        
    }
    render() {
        if(!isMobile){
        return (
            <div style={{backgroundColor: 'transparent', marginTop: 7, marginBottom: 7 , minWidth: 280}}>
                <Form inline onSubmit={this.onSubmit} >
                    <Form.Group controlId="formInput" >
                        <FormControl type={this.props.type} placeholder={this.props.defaultSearchText} 
                        style={{borderRadius: '15px', borderColor:  '#515151', borderWidth: 2 , fontFamily: 'BikoRegular'}}/>
                     </Form.Group>
                    <Button type="submit" style={
                    {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16, marginLeft:-30}}>
                        {this.props.buttonText}
                    </Button>
                </Form>
            </div>
        )}
        else
        return (
            <div style={{backgroundColor: 'transparent', minWidth: 280}}>
                <Form onSubmit={this.onSubmit} style = {{backgroundColor: 'transparent' , display: 'flex', flexDirection: 'column', marginBottom: 10, marginTop: 10}}>
                    <Form.Group controlId="formInput" >
                        <FormControl type={this.props.type} placeholder={this.props.defaultSearchText} 
                        style={{borderRadius: '15px', borderColor:  '#515151', borderWidth: 2 , fontFamily: 'BikoRegular', width: "100%", alignSelf: 'center'}}/>
                     </Form.Group>
                    <Button type="submit" style={
                    {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16, width: "80%", alignSelf: 'center'}}>
                        {this.props.buttonText}
                    </Button>
                </Form>
            </div>
        )

    }
}