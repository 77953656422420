import React from 'react';
import ExampleComponent from "react-rounded-image";

export default class VProfileImage extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }


    render() {
        return (
            <div >
                { this.props.url==null || this.props.url=="" &&
                    <ExampleComponent
                        image={require("./DefaultProfile.png")}
                        roundedColor="#ea2f64"
                        imageWidth={this.props.size}
                        imageHeight={this.props.size}
                        roundedSize={this.props.ringSize}
                    />
                }
                { this.props.url!=null && this.props.url!="" &&
                    <ExampleComponent
                        image={this.props.url}
                        roundedColor="#ea2f64"
                        imageWidth={this.props.size}
                        imageHeight={this.props.size}
                        roundedSize={this.props.ringSize}
                    />
                }                
            </div>
        )
    }
}