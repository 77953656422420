import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VEmailInvite from '../../components/EmailInvite/EmailInvite.js';
import {  db, auth, user } from '../../services/firebase';
import { isMobile } from 'react-device-detect';

export default class VBanner extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {               
        }        
    }

    render() {
        let mainFrase;
        if (!isMobile) { mainFrase = 
            <div style={{position:'relative', maxWidth: 1100, flexDirection: "row", backgroundColor: '#efefef', borderRadius: 25 , marginLeft:'auto', marginRight: 'auto' ,top:-50, overflow: 'show'}}>
                <p style={{fontSize: 25, fontFamily: 'BikoBold'}}> VIRTUALÍZALO es una plataforma libre para la visualización de contenido web  interactivo, que impacta positivamente fomentando la cultura digital en México. </p>
            </div>
         } 
        else { mainFrase =
            <div style={{position:'relative', maxWidth: 1100, flexDirection: "row", backgroundColor: '#efefef', borderRadius: 25 , marginLeft:'auto', marginRight: 'auto' ,top:-15, overflow: 'show'}}>
                <p style={{fontSize: 14, fontFamily: 'BikoBold', padding: 5}}> VIRTUALÍZALO es una plataforma libre para la visualización de contenido web  interactivo, que impacta positivamente fomentando la cultura digital en México. </p>
            </div>
         }

        return (
            <div id="home_section"  style={{height:'auto',width: "100%", marginTop: 0, flexDirection: "column", backgroundColor: '#303030' }}>
                <img style={{maxHeight: "100%", maxWidth: "100%"}} src={require("./Virtualizalo_Banner_1080.png")}alt="Third slide"/>
                <div style={{width: "100%", flexDirection: "row", backgroundColor: '#e2e2e2' }}>
                {mainFrase}
                <div style={{width: '100%', marginTop: -30, backgroundColor: 'transparent'}}>
                    <Container style={{ backgroundColor: 'transparent'}}>
                    <Row style={{ backgroundColor: 'transparent' }}>
                        <Col style= {{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{ backgroundColor: 'transparent' , marginTop: 10, marginBottom: 10}}>
                                <a href={"https://www.patreon.com/virtualizalo"}><img style={{ maxWidth: 200 , backgroundColor: 'transparent'}} src={require("./Patreon1_Support_512_esp.png")}/></a>
                            </div>
                        </Col>
                        <Col style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{ backgroundColor: 'transparent', marginTop: 10, marginBottom: 10}}>
                                 <VEmailInvite/>
                            </div>                            
                        </Col>                    
                    </Row>
                    </Container> 
                </div> 
                <div style={{height:50}}/>
                </div>             
            </div>
        )
    }
}