import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VNomineeItem from '../../components/NomineeItem/NomineeItem.js';
import { db } from '../../services/firebase';
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton} from "react-share";

export default class VVoting extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {  
            nomineesCollection:[],
            user: this.props.user
        }
        this.updatingVote = false;
    }

    componentDidMount = () => {
        this.updateUser();
        this.updateNominees();
    }

    async updateNominees(updateState = true){
        let nomineesCollection = db.collection('nominees');
        let nomineesCollectionMap = await nomineesCollection.get()
            .then(snapshot => {                
                var fullCollection = {};                
                snapshot.forEach(doc => {
                    fullCollection[doc.id] = doc.data()
                   // fullCollection.push({ id: doc.id, data: doc.data() });
                });                                
                this.nomineesCollection = fullCollection;
                if(updateState){
                    this.setState({ nomineesCollection: fullCollection });
                }
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }

    async updateUser(updateState = true){        
        if(this.props.user!=null){
            this.uid = this.props.user._id;
            var user = null;            
            let userDoc = db.collection('users').doc(this.props.user._id);            
            let getDoc = await userDoc.get()
                .then(doc => {
                    if (!doc.exists) {
                        //console.log('No document.');
                    } else {
                        user = doc.data();
                    }
                    this.state.user = user;
                    if(updateState){
                        this.setState({ user: user });
                    }
                })
                .catch(err => {
                    console.log('Error reading user ' + err);
                    this.state.user = user;
                    if(updateState){
                        this.setState({ user: user });
                    }
                });
        }
    }

    getVotesAvailable(){
        if(  this.state.user!=null  && this.state.user.votes!=null){
            return this.state.user.votes;
        }
        return 0;
    }

    getVoteMultiplier(){        
        if(  this.state.user!=null  && this.state.user.votesMultiplier!=null){
            return  this.state.user.votesMultiplier;
        }
        return 1.0;
    }

    onUpVote = (id) =>{
        this.tryToAddVote(id);
    }
    onDownVote = (id) =>{
        this.tryToRemoveVote(id);
    }


    hasUserVoted(nominee){
        if(this.state.user!=null && this.state.user.nomineesVoted!=null
            && this.state.user.nomineesVoted[nominee]!=null 
            && this.state.user.nomineesVoted[nominee].votes>0 ){
            
            return true;
        }
        return false;
    }

    addNomineeVoteToUser(nominee, vote){
        if(this.state.user!=null){
            if(this.state.user.nomineesVoted==null){
                this.state.user.nomineesVoted = {};
            }
            if( this.state.user.nomineesVoted[nominee]==null ){
                this.state.user.nomineesVoted[nominee] = {votes:0};
            }
            if( this.state.user.nomineesVoted[nominee].votes==null ){
                this.state.user.nomineesVoted[nominee].votes = 0;
            }

            if(this.state.user.votes==null){
                this.state.user.votes = 0;
            }        
            this.state.user.votes = this.state.user.votes + vote*-1;
            if(this.state.user.votes<0){
                this.state.user.votes = 0;
            }
            this.state.user.nomineesVoted[nominee].votes = this.state.user.nomineesVoted[nominee].votes+vote;        
            if(this.state.user.nomineesVoted[nominee].votes<0){
                this.state.user.nomineesVoted[nominee].votes = 0;
            }
            db.collection("users").doc(this.uid).set(
                this.state.user                    
            );
        }     
    }

    addNomineeVote(nominee, vote){        
        var nomineeObject =this.state.nomineesCollection[nominee];
        if(nomineeObject!=null){
            nomineeObject.votes = nomineeObject.votes + this.getVoteMultiplier()*vote;
            if(nomineeObject.votes<0){
                nomineeObject.votes = 0;
            }
            db.collection("nominees").doc(nominee).set(
                nomineeObject                    
            );
        }
    }
  

    async tryToAddVote(nominee){
        if(this.updatingVote){
            return;
        }
        this.updatingVote = true;
        await this.updateUser();
        await this.updateNominees();

        if(this.state.user!=null){
            if(this.getVotesAvailable()>0){
                this.addNomineeVote(nominee, 1);
                this.addNomineeVoteToUser(nominee, 1);
            }
        }
        this.updatingVote = false;
        this.setState(this.state);
    }

    async tryToRemoveVote(nominee){
        if(this.updatingVote){
            return;
        }
        this.updatingVote = true;        
        await this.updateUser();
        await this.updateNominees();

        if(this.state.user!=null){
            if(this.hasUserVoted(nominee)){
                this.addNomineeVote(nominee, -1);
                this.addNomineeVoteToUser(nominee, -1);
            }
        }
        this.updatingVote = false;
        this.setState(this.state);
    }    

    render() {

        var availableVotes = 0;
        var votesMultiplier = 1.0;

        if(this.state.user ==null && this.props.user!=null){
            this.uid = this.props.user._id;
            this.state.user = this.props.user;
        }

        if(  this.state.user!=null  && this.state.user.votes!=null){
            availableVotes = this.state.user.votes;
        }
        if(  this.state.user!=null  && this.state.user.votesMultiplier!=null){
            votesMultiplier = this.state.user.votesMultiplier;
        }        
        var totalVotes = 0;
        for (var key in this.state.nomineesCollection) {            
            totalVotes = totalVotes + this.state.nomineesCollection[key].votes;
        }
        

        var bg=require('./Background_Vota_1080.png')
        return (
            <div id="voting_section"  style={{width: "100%", marginTop: 0, flexDirection: "column",  backgroundImage: "url("+bg+")" , backgroundSize: 'cover' }}>
                <div style={{width: "100%", flexDirection: "row", backgroundColor: 'grtransparenteen' }}>
                    <Container style={{marginTop: 0, backgroundColor: 'transparent'}}>
                        <Row style={{ backgroundColor: 'transparent'}}>                    
                            <Col>
                                <img style={{ marginTop: 40, width: "45%"}} src={require("./Olas_Titulos_64.png")}/>
                            </Col>                    
                            <Col style={{ backgroundColor: 'transparent'}}>
                            <p style={{marginTop: 40, fontSize: 35, fontFamily: 'BikoBold'}}> VOTA Y VIRTUALIZALO </p>
                            </Col>                    
                            <Col>
                                <img style={{marginTop: 40, width: "45%"}} src={require("./Olas_Titulos_64.png")}/>
                            </Col>                    
                        </Row>
                    </Container>
                    <p style={{marginTop: 15,fontSize: 20, marginLeft:'auto', marginRight: 'auto', fontFamily: 'BikoBold'}}> No hay votación por el momento. </p>
                    <Container style={{marginTop: 50}}>
                        <Row >
                            <p style={{marginTop: 20,fontSize: 20, marginLeft:'auto', marginRight: 'auto', fontFamily: 'BikoRegular'}}> COMPARTIR VOTACIÓN </p>
                        </Row> 
                        <Row style={{width:300, marginLeft: 'auto', marginRight:'auto'}}>
                            <WhatsappShareButton url={'https://virtualizalo.com/#voting_section'}
                                title ={"¡Vota por virtualizado en Virtualizalo!"}
                                style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}}
                            >
                                <img style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}} src={require("./IconoNegro_Whatsapp_64.png")}alt="Third slide"/>
                            </WhatsappShareButton>
                            <TwitterShareButton url={'https://virtualizalo.com/#voting_section'}
                                title ={"¡Apoya a Virtualizalo!"}
                                hashtags ={["virtualizalo"]}
                                related ={["@virtualizalo"]}
                                style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}}
                            >
                                <img style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}} src={require("./IconoNegro_Twitter_64.png")}alt="Third slide"/>
                            </TwitterShareButton>
                            <FacebookShareButton url={'https://virtualizalo.com/#voting_section'}
                                quote ={"¡Apoya a Virtualizalo!"}
                                hashtag ={"#virtualizalo"}
                                style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}}
                            >
                                <img style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}} src={require("./IconoNegro_Facebook_64.png")}alt="Third slide"/>
                            </FacebookShareButton>
                            <RedditShareButton url={'https://virtualizalo.com/#voting_section'}
                                title ={"¡Vota por virtualizado en Virtualizalo!"}
                                style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}}
                            >
                                <img style={{ maxWidth: 50, marginLeft:'auto', marginRight: 'auto'}} src={require("./IconoNegro_Reddit_64.png")}alt="Third slide"/>
                            </RedditShareButton>
                        </Row>
                    </Container> 
                    <div style={{maxWidth: 800, flexDirection: "row", backgroundColor: 'transparent', marginLeft:'auto', marginRight: 'auto'}}>
                            <p style={{fontSize: 20, fontFamily: 'BikoRegular'}}> <br/>. </p>
                    </div>
                </div>               
            </div>
        )
    }
}