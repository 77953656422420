import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {  db, auth, user } from '../../services/firebase';

export default class VSignUp extends React.Component {
    
    constructor(props) {
        super(props); 
        var show = false;       
        if ( this.props.show!=null ){
            show = this.props.show;
        }
        this.state = { 
            show: show,
            error: ""
        }        
    }

    attempEmailRegister =(event) =>{
        event.preventDefault();
        var name = event.target.elements.formName.value
        var props = this.props;
        if( event.target.elements.formPass1.value!=event.target.elements.formPass2.value ){
            this.setState({error:"Las contraseñas no son iguales."});
            return;
        }
        
        auth().createUserWithEmailAndPassword(event.target.elements.formEmail.value, event.target.elements.formPass1.value)
        .then(res => {
            console.log(res)
            if (res.user){
                auth().languageCode = 'es';
                auth().currentUser.sendEmailVerification();            
                db.collection("users").doc(res.user.uid).set({
                    email: res.user.email,
                    username: name,
                    isAdmin: false,
                    visited:[],
                    liked:[],
                    messages:[],
                    isBanned: false,
                    votes: 1,
                    votesMultiplier: 1.0               
                  });
                props.onClose();
            }
        })
        .catch(e => {
            console.log(e)
        });        
    }

    render() {
        return (
            <Modal show={this.props.show}>
                <div style={{width: 300, marginLeft: 'auto', marginRight: 'auto', flex:1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                    <img style={{ maxHeight: 180, marginLeft: 'auto', marginRight:'auto', marginBottom: 30}} src={require("./LogoVirtualizalo_Camp_1024.png")}alt="Third slide"/>
                    <p style={styles.modalText}> Crea tu cuenta </p>
                    <form style={{width: 300}} onSubmit={this.attempEmailRegister}>     
                        <Form.Group controlId="formName" >
                            <Form.Control type="name" placeholder="Nombre Completo" style={styles.input}/>
                        </Form.Group>                   
                        <Form.Group controlId="formEmail" >
                            <Form.Control type="email" placeholder="Correo Electrónico" style={styles.input}/>
                        </Form.Group>
                        <Form.Group controlId="formPass1" >
                            <Form.Control type="password" placeholder="Contraseña" style={styles.input}/>
                        </Form.Group>
                        <Form.Group controlId="formPass2" >
                            <Form.Control type="password" placeholder="Confirmar Contraseña" style={styles.input}/>
                        </Form.Group>
                        <div style={{textAlign: 'center', fontSize: 10, textJustify: 'center', display: 'flex', marginLeft:'auto', marginRight: 'auto', flexDirection: 'column'}}>
                            <a style={{textAlign: 'center', fontSize: 10, textJustify: 'center'}}>
                                Al dar click en CONTINUAR, aceptas el aviso de privacidad.
                            </a>
                            <a style={{textAlign: 'center', fontSize: 10, textJustify: 'center', display: 'flex', marginLeft:'auto', marginRight: 'auto', marginBottom: 20}}
                                href='https://firebasestorage.googleapis.com/v0/b/virtualizalo-gc.appspot.com/o/public%2FAviso%20de%20Privacidad.pdf?alt=media&token=ca352877-3d61-483e-8a1a-58b725919823'>
                                <br/>Da click aquí para ver el aviso de privacidad.
                            </a>
                        </div>                       
                        <Button variant="primary" type="submit" style={styles.registerButton}>
                            CONTINUAR
                        </Button>
                    </form>
                    { this.state.error!="" &&
                        <p style={styles.errorText}> {this.state.error} </p>
                    }                    
                    <Button variant="primary" type="submit" style={styles.cancelButton} onClick={()=>{
                       // this.props.show = false;
                        this.props.onClose();
                    }}>
                            CANCELAR
                    </Button>                    
                </div>
            </Modal>
        )
    }
}

const styles = {
    input:{borderRadius: '15px', borderColor:  '#515151', borderWidth: 2 , fontFamily: 'BikoRegular', marginTop:10, marginBottom:10},
    registerButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 16, marginBottom:10, display: 'flex'},
    buttonGoogle: {borderColor:  '#dd4b39',borderRadius: '0px', backgroundColor: '#dd4b39'
        ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 16, marginBottom:30, display: 'flex'},                     
    modalText: {color:'#3f3f3f', fontSize: 30, fontFamily: 'BikoBold', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
    cancelButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 10, marginBottom:10, display: 'flex'},
    modalText: {color:'#3f3f3f', fontSize: 30, fontFamily: 'BikoBold', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
    errorText: {color:'#ed0000', fontSize: 20, fontFamily: 'BikoRegular', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
};