import React from 'react';

import VInfoModal from '../InfoModal/InfoModal.js';

import {
    Link
} from "react-router-dom";

export default class VSphereItem extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }


    render() {        
        return (
            <div style={{marginTop:50}}>
                <Link to={ "/vp/" + this.props.id } >
                    <p style={{fontSize: 30, color: 'white', marginBottom: 0, fontFamily: 'BikoBold'}}> {this.props.title} </p>
                    <img style={{maxHeight: 300}} src={this.props.data.bubbleUrl}alt="Third slide"/>  
                 </Link>
               <div style={{flex: 1, marginTop: -20, flexDirection: "row", justifyContent: 'flex-start'}} >                    
                    { this.props.favorite!=null &&
                        <VInfoModal title={this.props.data.title} description={this.props.data.description} img={this.props.data.bubbleUrl}/>
                    }
                    { this.props.favorite==null &&
                        <div style={{  marginRight: 245}}>
                            <VInfoModal title={this.props.title} description={this.props.data.description} img={this.props.data.bubbleUrl}/>
                        </div>
                    }
                    { this.props.favorite!=null && this.props.favorite==true &&
                        <img style={{ maxHeight: 35,  marginRight: 200}} src={require("./Estrella_Favorito_64.png")}alt="Third slide"/>
                    }
                    { this.props.favorite!=null && this.props.favorite==false &&
                        <img style={{ maxHeight: 35,  marginRight: 200}} src={require("./Estrella2_Favorito_64.png")}alt="Third slide"/>
                    }
               </div>
            </div>
        )
    }
}