import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VEmailInvite from '../../components/EmailInvite/EmailInvite.js';
import YouTube from 'react-youtube';
import { isMobile } from 'react-device-detect';

import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton} from "react-share";

export default class VCampaign extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }

    
    render() {
        var bg=require('./Background_Camp_1080.png')
        
        let videoPart;
		if (!isMobile) { videoPart = 
            <div style={{background: 'transparent', marginTop: 50}}>
                <YouTube videoId="9NMvueyDwnE" opts={{width:"100%"}} onReady={this._onReady}/>
            </div>
		} else { videoPart = 
            <div style={{background: 'transparent', marginTop: 50}}>
                <YouTube videoId="9NMvueyDwnE" opts={{width:300, height: 170}} onReady={this._onReady}/>
            </div>
		}
        
        return (
            <div id="campaign_section"  style={{width: "100%", marginTop: 0, flexDirection: "column",  backgroundImage: "url("+bg+")" , backgroundSize: 'cover' }}>
                <div style={{width: "100%", flexDirection: "row" }}>
                    <Container style={{marginTop: 0, width: "100%"}}>
                        <Row style={{marginTop: 0, width: "100%"}}>                    
                            <Col>
                                <Container style={{marginTop: 0, background: 'transparent', marginTop: 50, marginBottom: 20}}>
                                    <Row style={{background: 'transparent'}} >                    
                                        <Col>
                                            <img style={{width: '100%' }} src={require("./Olas_Titulos_64.png")}/>
                                        </Col>                    
                                        <Col>
                                        <p style={{fontSize: 30, fontFamily: 'BikoBold'}}> CAMPAÑA </p>
                                        </Col>                    
                                        <Col >
                                            <img style={{ width: '100%'}} src={require("./Olas_Titulos_64.png")}/>
                                        </Col>                    
                                    </Row>
                                    <Row style={{background: 'transparent'}}>
                                        <Col>
                                            <div style={{background: 'transparent'}}>
                                                <a href={"https://www.patreon.com/virtualizalo"}>
                                                    <img style={{width: '70%'}} src={require("./Patreon3_Support_512.png")}/>
                                                </a> 
                                            </div>
                                        </Col>
                                        <Col style={{background: 'transparent'}}> 
                                            <div style={{background: 'transparent'}} >
                                                <img style={{width: '100%' }} src={require("./LogoVirtualizalo_Camp_1024.png")}/>
                                            </div>
                                        </Col>
                                    </Row> 
                                    <Row >
                                        <p style={{marginTop: 50,fontSize: 22, marginLeft:'auto', marginRight: 'auto', fontFamily: 'BikoRegular'}}> COMPARTIR CAMPAÑA </p>
                                    </Row> 
                                    <Row style={{backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <div style={{backgroundColor: 'transparent', display: 'flex'}}> 
                                        <WhatsappShareButton url={'https://virtualizalo.com/#campaign_section'}
                                            title ={"¡Apoya a Virtualizalo!"}
                                        >
                                            <img style={{ maxWidth: 45, marginLeft: 10, marginRight: 10}} src={require("./IconoNegro_Whatsapp_64.png")}alt="Third slide"/>
                                        </WhatsappShareButton>
                                        <TwitterShareButton url={'https://virtualizalo.com/#campaign_section'}
                                            title ={"¡Apoya a Virtualizalo!"}
                                            hashtags ={["virtualizalo"]}
                                            related ={["@virtualizalo"]}
                                        >
                                            <img style={{ maxWidth: 45, marginLeft: 10, marginRight: 10}} src={require("./IconoNegro_Twitter_64.png")}alt="Third slide"/>
                                        </TwitterShareButton>
                                        <FacebookShareButton url={'https://virtualizalo.com/#campaign_section'}
                                            quote ={"¡Apoya a Virtualizalo!"}
                                            hashtag ={"#virtualizalo"}
                                        >
                                            <img style={{ maxWidth: 45, marginLeft: 10, marginRight: 10}} src={require("./IconoNegro_Facebook_64.png")}alt="Third slide"/>
                                        </FacebookShareButton>
                                        <RedditShareButton url={'https://virtualizalo.com/#campaign_section'}
                                            title ={"¡Apoya a Virtualizalo!"}
                                        >
                                            <img style={{ maxWidth: 45, marginLeft: 10, marginRight: 10}} src={require("./IconoNegro_Reddit_64.png")}alt="Third slide"/>
                                        </RedditShareButton>
                                        </div>
                                    </Row>  
                                    <Row>
                                        <div style={{marginLeft:'auto', marginRight: 'auto', marginTop: 30}}>
                                                <VEmailInvite/>
                                        </div>
                                    </Row>
                                </Container>
                            </Col>                    
                            <Col>
                                {videoPart}
                                
                                <div style={{ flexDirection: "row", backgroundColor: 'transparent', marginLeft:'auto', marginRight: 'auto', marginTop: 20, textAlign: 'justify'}}>
                                    <p style={{fontSize: 20, fontFamily: 'BikoRegular'}}> Apoya a que Virtualízalo sea una plataforma libre, donde cualquiera pueda acceder a multiples espacios de alto impacto social en la sociedad mexicana. Conviértete en Patreon e invita a otros a serlo, si tenemos más patreons podremos virtualizar más proyectos.</p>
                                </div>
                            </Col>                                                
                        </Row>
                    </Container>  
                    <div style={{height: 50}} />                                   
                </div>               
            </div>
        )
    }
}