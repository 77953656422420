import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import YouTube from 'react-youtube';
import Button from 'react-bootstrap/Button';
import VEmailInvite from '../../components/EmailInvite/EmailInvite.js';
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton} from "react-share";
import { isMobile } from 'react-device-detect';

export default class VUs extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }


    render() {
        const opts = {
            width: '100%',
            height: '1080',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 1,
              controls: 0,
              disablekb: 1,
              loop: 1,
              modestbranding: 1,
              showinfo: 0,
              fs:0,
              rel:0
            }
          };
          const opts2 = {
            width: '100%',
            //height: '400',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 1,
              controls: 0,
              disablekb: 1,
              loop: 1,
              modestbranding: 1,
              showinfo: 0,
              fs:0,
              rel:0
            }
          };
        var bg=require('./Background2_AcercaDe_1920.png')
        var gradient=require('./gradient.jpg')
        
        let videoPart;
		if (!isMobile) { videoPart = 
                    <div style={{zIndex:0,height:800, overflow: 'hidden', backgroundColor: 'black'}}>  
                        <div style={{marginTop:-175}}> 
                            <YouTube videoId="9NMvueyDwnE" opts={opts} onReady={this._onReady}/>
                        </div>
                    </div>
		} else { videoPart = 
                    <div style={{zIndex:0, overflow: 'hidden', backgroundColor: 'black'}}>  
                        <div style={{marginTop:0}}> 
                            <YouTube videoId="9NMvueyDwnE" opts={opts2} onReady={this._onReady}/>
                        </div>
                    </div>
		}

        return (
            <div id="us_section" style={{zIndex:0, width: "100%", marginTop: 0, flexDirection: "column", backgroundColor: '#616264' }}>
                {videoPart}
                <div style={{position:'relative', maxWidth: 600, flexDirection: "row", backgroundColor: '#efefef', borderRadius: 50 , marginLeft:'auto', marginRight: 'auto' ,top:-50, overflow: 'show'}}>
                    <p style={{fontSize: 30, fontFamily: 'BikoBold'}}> Contenido interactivo 360 en un <br/>mismo lugar. </p>
                </div>                
                <div style={{width: "100%", flexDirection: "row",  backgroundImage: "url("+gradient+")" , backgroundColor: 'red', backgroundSize: 'contain' }}>   
                    
                    <p style={{fontSize: 30, fontFamily: 'BikoBold', color: 'white'}}>Lo que VIRTUALÍZALO ofrece:</p>             
                    <Container style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                        <Row style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                            <Col>                                           
                                <img style={{ maxWidth: 320}} src={require("./Dif1_AcercaDe_1024.png")}alt="Third slide"/>
                                <p style={styles.subTitleWhiteText}>360</p>  
                                <div style={styles.whiteDivLine} />
                                <p style={styles.regularWhiteText}>Visualización 360 interactivo en un solo lugar de manera fácil, con contenido de alto impacto cultural y social.</p>  
                            </Col>
                            <Col>
                                <img style={{ maxWidth: 320}} src={require("./Dif2_AcercaDe_1024.png")}alt="Third slide"/>
                                <p style={styles.subTitleWhiteText}>VOTA</p>  
                                <div style={styles.whiteDivLine} />
                                <p style={styles.regularWhiteText}>Votación de la comunidad para lanzamiento de nuevo contenido interactivo.</p>  
                            </Col>
                            <Col>
                                <img style={{ maxWidth: 320}} src={require("./Dif3_AcercaDe_1024.png")}alt="Third slide"/>
                                <p style={styles.subTitleWhiteText}>EXPERIENCIA</p>  
                                <div style={styles.whiteDivLine} />
                                <p style={styles.regularWhiteText}>Visualización entretenida. Crea una cuenta y disfruta del contenido desbloqueable y coleccionable.</p>  
                            </Col>                            
                        </Row>
                    </Container>                
                </div>
                <div style={{width: "100%", marginTop: 0, flexDirection: "column",  backgroundImage: "url("+bg+")" , backgroundSize: 'cover' }}>
                <Container style={{marginTop: 0}}>
                        <Row >                    
                            <Col>
                                <img style={{ marginTop: 50, maxHeight: 30, marginLeft:'auto', marginRight: 'auto'}} src={require("./Olas_Titulos_64.png")}alt="Third slide"/>
                            </Col>                    
                            <Col>
                            <p style={{marginTop: 50,fontSize: 40, marginLeft:'auto', marginRight: 'auto', fontFamily: 'BikoBold'}}> VIRTUALIZADO </p>
                            </Col>                    
                            <Col>
                                <img style={{marginTop: 50, maxHeight: 30, marginLeft:'auto', marginRight: 'auto'}} src={require("./Olas_Titulos_64.png")}alt="Third slide"/>
                            </Col>                    
                        </Row>
                    </Container> 
                    <p style={styles.subTitleDarkText}>Virtualiza tu proyecto</p>  
                    <Container style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                        <Row style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                            <Col>                                           
                                <img style={{ maxWidth: 320}} src={require("./Virtualizado1_AcercaDe_1024.png")}alt="Third slide"/>                                
                                <div style={styles.darkDivLine} />
                                <p style={styles.regularDarkText}>Comunica tu proyecto en la plataforma y crea comunidad en torno a él con el sistema de comentarios.</p>  
                            </Col>
                            <Col>
                                <img style={{ maxWidth: 320}} src={require("./Virtualizado2_AcercaDe_1024.png")}alt="Third slide"/>                                
                                <div style={styles.darkDivLine} />
                                <p style={styles.regularDarkText}>Exposición de proyectos y reporte de visualización de contenido.</p>  
                            </Col>
                            <Col>
                                <img style={{ maxWidth: 320}} src={require("./Virtualizado3_AcercaDe_1024.png")}alt="Third slide"/>                                
                                <div style={styles.darkDivLine} />
                                <p style={styles.regularDarkText}>Marketplace y sistema de recompensas por las donaciones realizadas.</p>  
                            </Col>                            
                        </Row>
                    </Container> 
                    <Button href='https://gcvirtual.typeform.com/to/Q1f926' variant="primary" style={
                        {borderColor:  '#7144b7',borderRadius: '15px', width: 240, backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 20, 
                            marginLeft:'auto', marginRight: 'auto', marginTop: 30}}>
                            REGISTRA TU <br/> PROYECTO
                    </Button>
                    <Container style={{marginTop: 0}}>
                        <Row >                    
                            <Col>
                                <img style={{ marginTop: 50, maxHeight: 30, marginLeft:'auto', marginRight: 'auto'}} src={require("./Olas_Titulos_64.png")}alt="Third slide"/>
                            </Col>                    
                            <Col>
                            <p style={{marginTop: 50,fontSize: 35, marginLeft:'auto', marginRight: 'auto', fontFamily: 'BikoBold'}}> VIRTUALIZADORES </p>
                            </Col>                    
                            <Col>
                                <img style={{marginTop: 50, maxHeight: 30, marginLeft:'auto', marginRight: 'auto'}} src={require("./Olas_Titulos_64.png")}alt="Third slide"/>
                            </Col>                    
                        </Row>
                    </Container> 
                    <p style={styles.subTitleDarkText}>Se parte de la comunidad</p>  
                    <Container style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                        <Row style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                            <Col>                                           
                                <img style={{ maxWidth: 320}} src={require("./Virtualizadores1_AcercaDe_1024.png")}alt="Third slide"/>                                
                                <div style={styles.darkDivLine} />
                                <p style={styles.regularDarkText}>Forma parte de la primera comunidad de contenido 360 interactivo en México.</p>  
                            </Col>
                            <Col>
                                <img style={{ maxWidth: 320}} src={require("./Virtualizadores2_AcercaDe_1024.png")}alt="Third slide"/>                                
                                <div style={styles.darkDivLine} />
                                <p style={styles.regularDarkText}>Comenta, vota, apoya y contacta con los creadores de cada proyecto de manera fácil y directa..</p>  
                            </Col>
                            <Col>
                                <img style={{ maxWidth: 320}} src={require("./Virtualizadores3_AcercaDe_1024.png")}alt="Third slide"/>                                
                                <div style={styles.darkDivLine} />
                                <p style={styles.regularDarkText}>Desbloquea el contenido coleccionable de VIRTUALÍZALO y obten recompensas por donaciones.</p>  
                            </Col>                            
                        </Row>
                    </Container>                     
                    <Container style={{ marginTop: 50, marginBottom: 'auto'}}>
                        <Row style={{ backgroundColor: 'transparent'}}>
                            <Col style={{ backgroundColor: 'transparent',  marginTop: 20, marginBottom: 20}}>                      
                                <p style={styles.subTitleDarkText}>COMPARTIR CAMPAÑA</p>                     
                                <WhatsappShareButton url={'https://virtualizalo-gc.web.app/'}
                                    title ={"¡Apoya a Virtualizalo!"}
                                >
                                    <img style={{ maxWidth: 50 }} src={require("./IconoNegro_Whatsapp_64.png")}/>
                                </WhatsappShareButton>
                                <TwitterShareButton url={'https://virtualizalo-gc.web.app/'}
                                    title ={"¡Apoya a Virtualizalo!"}
                                    hashtags ={["virtualizalo"]}
                                    related ={["@virtualizalo"]}
                                >
                                    <img style={{ maxWidth: 50 }} src={require("./IconoNegro_Twitter_64.png")}/>
                                </TwitterShareButton>
                                <FacebookShareButton url={'https://virtualizalo-gc.web.app/'}
                                    quote ={"¡Apoya a Virtualizalo!"}
                                    hashtag ={"#virtualizalo"}
                                >
                                    <img style={{ maxWidth: 50 }} src={require("./IconoNegro_Facebook_64.png")}/>
                                </FacebookShareButton>
                                <RedditShareButton url={'https://virtualizalo-gc.web.app/'}
                                    title ={"¡Apoya a Virtualizalo!"}
                                >
                                    <img style={{ maxWidth: 50 }} src={require("./IconoNegro_Reddit_64.png")}/>
                                </RedditShareButton>
                            </Col>
                            <Col style={{ backgroundColor: 'transparent',  marginTop: 20, marginBottom: 20}}>
                                <a href={"https://www.patreon.com/virtualizalo"}>
                                    <img style={{ maxWidth: 320}} src={require("./Patreon1_Support_512.png")}/>
                                </a>
                            </Col>
                            <Col style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <div variant="primary" style = {{ marginTop: 20, marginBottom: 20}}>
                                    <VEmailInvite/>
                                </div>
                            </Col>                            
                        </Row>
                    </Container>
                    <Container style={{marginTop: 0}}>
                        <Row >                    
                            <Col>
                                <img style={{ marginTop: 50, maxHeight: 30, marginLeft:'auto', marginRight: 'auto'}} src={require("./Olas_Titulos_64.png")}alt="Third slide"/>
                            </Col>                    
                            <Col>
                            <p style={{marginTop: 50,fontSize: 40, marginLeft:'auto', marginRight: 'auto', fontFamily: 'BikoBold'}}> COLABORADOR </p>
                            </Col>                    
                            <Col>
                                <img style={{marginTop: 50, maxHeight: 30, marginLeft:'auto', marginRight: 'auto'}} src={require("./Olas_Titulos_64.png")}alt="Third slide"/>
                            </Col>                    
                        </Row>
                    </Container>                     
                    <Container style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                        <Row style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                            <Col>                                           
                            <p style={styles.subTitleDarkText}>¿Te declaras entusiasta de las nuevas tecnologías?
¿Crees que VIRTUALÍZALO puede ser parte de tu proyecto? ¿Te gustaría subir contenido exclusivo para mostrar tu marca o iniciativa? ¿Cuentas con equipo de captura 360 y te gustaría contribuir con contenido? ¿Te gustaría saber más de VIRTUALÍZALO?</p>  
                            </Col>                           
                        </Row>
                    </Container> 
                    <Button href='https://gcvirtual.typeform.com/to/pOTUmF' variant="primary" style={
                        {borderColor:  '#7144b7',borderRadius: '15px', width: 240, backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 20, 
                            marginLeft:'auto', marginRight: 'auto', marginTop: 30}}>
                            CONTÁCTANOS
                    </Button>                    
                    <div style={{height:50}}/>                  
                </div>          
            </div>
        )
    }
}

const styles = {
    whiteDivLine: {width: 200, height: 3, marginTop: 10, marginBottom: 10, flexDirection: "row", backgroundColor: 'white', marginLeft:'auto', marginRight: 'auto'},
    regularWhiteText: {fontSize: 15, fontFamily: 'BikoRegular', color: 'white'},
    subTitleWhiteText:{fontSize: 25, fontFamily: 'BikoBold', color: 'white'},
    darkDivLine: {width: 200, height: 3, marginTop: 10, marginBottom: 10, flexDirection: "row", backgroundColor: '#494949', marginLeft:'auto', marginRight: 'auto'},
    regularDarkText: {fontSize: 15, fontFamily: 'BikoRegular', color: '#494949'},
    subTitleDarkText:{fontSize: 25, fontFamily: 'BikoBold', color: '#494949'},
}