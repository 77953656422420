import firebase from 'firebase';
const config = {
  apiKey: "AIzaSyBnhHeFx30jxIYldo71kZPIfGNqdtjFIfI",
  authDomain: "virtualizalo-gc.firebaseapp.com",
  databaseURL: "https://virtualizalo-gc.firebaseio.com",
  projectId: "virtualizalo-gc",
  storageBucket: "virtualizalo-gc.appspot.com",
  messagingSenderId: "647796956880",
  appId: "1:647796956880:web:1a2d99a09bbfb7d71748d5",
  measurementId: "G-WMWZYNPGVL"
};
firebase.initializeApp(config);
firebase.analytics();
export const auth = firebase.auth;
export const db = firebase.firestore();
export const storage = firebase.storage();