import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {  db, auth, user, storage } from '../../services/firebase';
import { ImagePicker } from 'react-file-picker'
import VProfileImage from '../ProfileImage/ProfileImage.js'
import Resizer from 'react-image-file-resizer';

export default class VProfile extends React.Component {
    
    constructor(props) {
        super(props); 
        var show = false;       
        if ( this.props.show!=null ){
            show = this.props.show;
        }
        this.state = { 
            show: show,
            user: this.props.user,
            justUploadadPhoto: '',
            errorMessage: '',
            showRecoverPasswordConfirmation: false
        }         
    }

    recoverPasswordConfirmation = () =>{
        this.setState( {
            showRecoverPasswordConfirmation: true            
        } );
      }
    
      recoverPassword = (event) =>{
        auth().languageCode = 'es';
        auth().sendPasswordResetEmail(this.props.user.dbUser.email);
        this.setState({recoverSent:true});
      } 

    updateUserPhotoUrl = (url) =>{        
        this.props.user.dbUser.photoUrl = url;
        db.collection("users").doc(this.props.user.authUser.uid).set(
            this.props.user.dbUser
        );          
        this.setState({justUploadadPhoto: url})
    }

    handleUpload = (image) => {          
        this.setState({errorMessage: ''});   

        fetch(image).then(res =>res.blob()).then( blob =>
            {                            
                Resizer.imageFileResizer(
                    blob,
                    300,
                    300,
                    'JPEG',
                    50,
                    0,
                    uri => {
                        fetch(uri).then(res =>res.blob()).then( blob =>
                            {                                                                 
                                let r = Math.random().toString(36).substring(7);
                                const uploadTask = storage.ref('images/' + this.props.user.dbUser.username + r).put(blob );
                                uploadTask.on(
                                "state_changed",
                                snapshot => {
                                    // progress function ...
                                    const progress = Math.round(
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                    );
                                    //this.setState({ progress });
                                },
                                error => {
                                    // Error function ...                
                                    console.log(error);
                                },
                                () => {
                                    // complete function ...
                                    storage
                                    .ref("images")
                                    .child(this.props.user.dbUser.username + r)
                                    .getDownloadURL()
                                    .then(url => {
                                        this.updateUserPhotoUrl(url );
                                    });
                                }
                                );
                        })
                    },
                    'base64'
                );

        }) 
      };


    render() {        

        var photoUrl = ''                
        if( this.props.user!=null  && this.props.user.dbUser.photoUrl!=null && this.props.user.dbUser.photoUrl != ''  ){
            this.state.justUploadadPhoto = this.props.user.dbUser.photoUrl;
        }        

        return (
            <Modal show={this.props.show}>
                <div style={{width: 300, marginLeft: 'auto', marginRight: 'auto', flex:1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>                                            
                    <div style={{ marginLeft:'auto', marginRight: 'auto',display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', marginBottom: 10, marginTop:10}}>
                        < VProfileImage 
                            size= '150'
                            url = {this.state.justUploadadPhoto}
                            ringSize = '12'
                        />
                    </div>                        
                    <ImagePicker
                            extensions={['jpg', 'jpeg', 'png']}
                            dims={{minWidth: 16, maxWidth: 8192, minHeight: 16, maxHeight: 8192}}
                            onChange={this.handleUpload }
                            onError={ (errMsg)=> {this.setState({errorMessage: 'Tu imagen no cumple las características solicitadas.'});} }
                        >
                            <Button variant="primary" type="submit" style={styles.cancelButton} >
                                    CAMBIAR FOTO
                            </Button> 
                        </ImagePicker>
                        

                    <p style={{fontSize: 10, fontFamily: 'BikoRegular', marginLeft:'auto', marginRight: 'auto',display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'}}>Formato de archivo: jpg o png, cuadrada.</p>
                    { this.state.errorMessage!="" &&
                        <p style={styles.errorText}> {this.state.errorMessage} </p>
                    }
                    {this.props.user!=null &&
                        <p style={{fontSize: 30, fontFamily: 'BikoBold', marginLeft:'auto', marginRight: 'auto',display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'}}> {this.props.user.dbUser.username} </p>
                    }

                    {this.props.user!=null &&
                        <p style={{fontSize: 20, fontFamily: 'BikoRegular', marginLeft:'auto', marginRight: 'auto',display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'}}> {this.props.user.dbUser.email} </p>
                    }

                    <Button href="https://gcvirtual.typeform.com/to/Q1f926" variant="primary" type="submit" style={styles.registerButton} onClick={()=>{
                       // this.props.show = false;
                        this.props.onClose();
                    }}>
                      REGISTRAR PROYECTO
                    </Button>                     
                    <Button variant="primary" type="submit" style={styles.cancelButton} onClick={()=>{
                       // this.props.show = false;
                       this.setState({showRecoverPasswordConfirmation:true})
                    }}>
                            REINICIAR CONTRASEÑA
                    </Button> 
                    <div style={{height:25}} />
                    <Button variant="primary" type="submit" style={styles.cancelButton} onClick={()=>{
                       // this.props.show = false;
                        this.props.onClose();
                    }}>
                            CERRAR
                    </Button>                    
                </div>
                <Modal show={this.state.showRecoverPasswordConfirmation} onHide={()=>{this.setState({showRecoverPasswordConfirmation:false, recoverSent: false})}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Reiniciar contraseña</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!this.state.recoverSent &&
                            <form  
                            onSubmit={this.recoverPassword}
                            >
                                ¿Deseas reiniciar contraseña? Un correo se enviará para reiniciar la contraseña.                       
                                <Button variant="primary" 
                                    type="submit"
                                    style={
                                    {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16, 
                                    marginLeft:'auto', marginRight: 'auto', marginTop: -8, marginBottom: 'auto',display: 'flex'}}
                                >
                                    Reiniciar contraseña.
                                </Button>                                    
                            </form >
                        }
                        {this.state.recoverSent &&
                        <p> Se envió correo para reiniciar contraseña. </p>
                        }
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" onClick={()=>{this.setState({showRecoverPasswordConfirmation:false, recoverSent: false})}}
                        style={
                            {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7', fontFamily: 'BikoBlack', fontSize: 16, 
                            marginLeft:'auto', marginRight: 'auto', marginTop: -8, marginBottom: 'auto',display: 'flex'}}
                        >
                            Cerrar
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </Modal>
        )
    }
}

const styles = {
    input:{borderRadius: '15px', borderColor:  '#515151', borderWidth: 2 , fontFamily: 'BikoRegular', marginTop:10, marginBottom:10},
    registerButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 16, marginBottom:10, display: 'flex'},
    buttonGoogle: {borderColor:  '#dd4b39',borderRadius: '0px', backgroundColor: '#dd4b39'
        ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 16, marginBottom:30, display: 'flex'},                     
    modalText: {color:'#3f3f3f', fontSize: 30, fontFamily: 'BikoBold', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
    cancelButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 10, marginBottom:10, display: 'flex'},
    registerButton: {borderColor:  '#7144b7',borderRadius: '15px', backgroundColor: '#7144b7'
                     ,marginLeft: 'auto', marginRight: 'auto'   , fontFamily: 'BikoBlack', fontSize: 10, marginBottom:10, display: 'flex', width:150},                     
    modalText: {color:'#3f3f3f', fontSize: 30, fontFamily: 'BikoBold', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
    errorText: {color:'#ed0000', fontSize: 10, fontFamily: 'BikoRegular', marginLeft: 'auto', marginRight:'auto',  justifyContent:'center', alignItems:'center', display: 'flex'},
};