import React from 'react';
import { auth, db, storage } from './../../services/firebase';
import VMainHeader from './../../sections/MainHeader/MainHeader.js';
import VMenuHeader from './../../sections/MenuHeader/MenuHeader.js';
import VCarousel from './../../sections/Carousel/Carousel.js';
import VCatalogue from './../../sections/Catalogue/Catalogue.js';
import VBanner from './../../sections/Banner/Banner.js';
import VVoting from './../../sections/Voting/Voting.js';
import VCampaign from './../../sections/Campaign/Campaign.js';
import VUs from './../../sections/Us/Us.js';
import VFooter from './../../sections/Footer/Footer.js';
import VVirtualPlayerGood from './../../sections/VirtualPlayer/VirtualPlayerGood.js';
import UserProvider from "./../../providers/UserProvider.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

var reactObj;

export default class VMain extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user: null,
			fullScreen: false
		}
	}

	componentDidMount() {
		reactObj = this;
	}

	setUserCallback(user) {
		console.log("USER", user);
		reactObj.setState({ user: user });
	}

	getUserCallback() {
		return reactObj.state.user;
	}
	
	toggleFullScreen(){
		reactObj.setState({fullScreen:!reactObj.state.fullScreen});
	}

	render() {
		if (!reactObj) {
			reactObj = this;
		}		
		let vp = <Route path="/vp/:CID" render={(props) => <VVirtualPlayerGood expanded ={reactObj.state.fullScreen} {...props} userCallback={this.getUserCallback} onFullscreenToggle={this.toggleFullScreen} />} />;
		if (this.state.user) {
			vp = <Route path="/vp/:CID" render={(props) => <VVirtualPlayerGood expanded ={reactObj.state.fullScreen} {...props} UID={this.state.user._id} userCallback={this.getUserCallback}  onFullscreenToggle={this.toggleFullScreen} />} />;
		}		
		return (
			<UserProvider>
				<div className="App" style={{ backgroundColor: '#303030' }}>
					<Router>
						{this.state.fullScreen === false &&
							<VMainHeader userCallback={this.setUserCallback} history={this.props.history}/>
						}
						{this.state.fullScreen === false &&
							<VMenuHeader />
						}		
						<Switch>
							{vp}
							<Route path="/votacion">
								<VVoting />
								<VFooter />
							</Route>							
							<Route path="/patreon">
								<VCampaign />
								<VFooter />
							</Route>							
							<Route path="/nosotros">
								<VUs />
								<VFooter />
							</Route>							
							<Route path="/">
								<VBanner />
								<VCarousel />
								<VCatalogue />
								<VVoting user={this.state.user}/>
								<VCampaign />
								<VFooter />
							</Route>							
						</Switch>

					</Router>
				</div>
			</UserProvider >
		)
	}
}