import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VSphereItem from '../../components/SphereItem/SphereItem.js';
import { db, auth } from '../../services/firebase';
import { isMobile } from 'react-device-detect';


export default class VCatalogue extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toursCollection: []
        }

        let toursCollection = db.collection('content');
        let toursCollectionMap = toursCollection.get()
            .then(snapshot => {
                console.log("Snapshot")
                var fullCollection = []
                snapshot.forEach(doc => {
                    fullCollection.push({ id: doc.id, data: doc.data() });
                });
                console.log("Snapshot finished")
                console.log(fullCollection)
                this.setState({ toursCollection: fullCollection });
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }


    render() {

        let mobileVar;
		if (!isMobile) { mobileVar = 
            <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "303030" }} variant="dark" >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Nav className="mr-auto" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Nav.Link href="#features" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Container style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Row style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Col style={{ width: '100%', marginLeft: 'auto', marginRight: 5 }}>
                                            <img style={{ maxHeight: 100 }} src={require("./Museografia_Categoria_512.png")} alt="Third slide" />
                                        </Col>
                                        <Col style={{ width: '100%', marginLeft: -35, marginRight: 0, marginTop: 'auto', marginBottom: 'auto' }}>
                                            <a style={{ fontSize: 18, color: '#7144b7', fontFamily: 'BikoRegular' }}> MUSEOGRAFÍA E INSTALACIONES </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Nav.Link>
                            <Nav.Link href="#features" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Container style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Row style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Col style={{ width: '100%', marginLeft: 'auto', marginRight: 5 }}>
                                            <img style={{ maxHeight: 100 }} src={require("./FloraFauna_Categoria_512.png")} alt="Third slide" />
                                        </Col>
                                        <Col style={{ width: '100%', marginLeft: -35, marginRight: 0, marginTop: 'auto', marginBottom: 'auto' }}>
                                            <a style={{ fontSize: 18, color: '#54d186', fontFamily: 'BikoRegular' }}> FLORA Y FAUNA </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Nav.Link>
                            <Nav.Link href="#features" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Container style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Row style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Col style={{ width: '100%', marginLeft: 'auto', marginRight: 5 }}>
                                            <img style={{ maxHeight: 100 }} src={require("./MusicaDanza_Categoria_512.png")} alt="Third slide" />
                                        </Col>
                                        <Col style={{ width: '100%', marginLeft: -35, marginRight: 0, marginTop: 'auto', marginBottom: 'auto' }}>
                                            <a style={{ fontSize: 18, color: '#ea2f64', fontFamily: 'BikoRegular' }}> MÚSICA Y DANZA </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <Nav className="mr-auto">
                        <Nav.Link href="#features" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Container style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Row style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Col style={{ width: '100%', marginLeft: 'auto', marginRight: 5 }}>
                                        <img style={{ maxHeight: 100 }} src={require("./SaludPsico_Categoria_512.png")} alt="Third slide" />
                                    </Col>
                                    <Col style={{ width: '100%', marginLeft: -35, marginRight: 0, marginTop: 'auto', marginBottom: 'auto' }}>
                                        <a style={{ fontSize: 18, color: '#4cd8ca', fontFamily: 'BikoRegular' }}> SALUD Y PSICOLOGÍA </a>
                                    </Col>
                                </Row>
                            </Container>
                        </Nav.Link>
                        <Nav.Link href="#features" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Container style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Row style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Col style={{ width: '100%', marginLeft: 'auto', marginRight: 5 }}>
                                        <img style={{ maxHeight: 100 }} src={require("./ArquiUrban_Categoria_512.png")} alt="Third slide" />
                                    </Col>
                                    <Col style={{ width: '100%', marginLeft: -35, marginRight: 0, marginTop: 'auto', marginBottom: 'auto' }}>
                                        <a style={{ fontSize: 18, color: '#efb941', fontFamily: 'BikoRegular' }}> ARQUITECTURA Y URBANISMO </a>
                                    </Col>
                                </Row>
                            </Container>
                        </Nav.Link>
                        <Nav.Link href="#features" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Container style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Row style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Col style={{ width: '100%', marginLeft: 'auto', marginRight: 5 }}>
                                        <img style={{ maxHeight: 100 }} src={require("./Experimental_Categoria_512.png")} alt="Third slide" />
                                    </Col>
                                    <Col style={{ width: '100%', marginLeft: -35, marginRight: 0, marginTop: 'auto', marginBottom: 'auto' }}>
                                        <a style={{ fontSize: 18, color: '#d63eaf', fontFamily: 'BikoRegular' }}> EXPERIMENTAL </a>
                                    </Col>
                                </Row>
                            </Container>
                        </Nav.Link>
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
		} else { mobileVar = 
            <div style= {{backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <div style= {{width: '100%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginRight: 5, marginTop: 10}}>
                
                <div style= {{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style= {{  backgroundColor: 'transparent'}} >
                        <img style={{ height: 50 , marginTop: 20 }} src={require("./Museografia_Categoria_512.png")} />
                    </div>
                    
                    <div style= {{ }}>
                        <a style={{ fontSize: 15, color: '#7144b7', fontFamily: 'BikoRegular' }}>MUSEOS E INSTALACIONES</a>
                    </div>
                </div>    
                
                <div style= {{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style= {{  backgroundColor: 'transparent'}} >
                        <img style={{ height: 50 , marginTop: 20 }} src={require("./SaludPsico_Categoria_512.png")} />
                    </div>
                    
                    <div style= {{ }}>
                        <a style={{ fontSize: 15, color: '#4cd8ca', fontFamily: 'BikoRegular' }}>SALUD Y PSICOLOGÍA</a>
                    </div>
                </div>

                <div style= {{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style= {{  backgroundColor: 'transparent'}} >
                        <img style={{ height: 50 , marginTop: 20 }} src={require("./MusicaDanza_Categoria_512.png")} />
                    </div>
                    
                    <div style= {{ }}>
                        <a style={{ fontSize: 15, color: '#ea2f64', fontFamily: 'BikoRegular' }}>MÚSICA Y DANZA</a>
                    </div>
                </div>    
            
            </div>    
            
            <div style= {{width: '100%',backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 5, marginTop: 10}}>
                <div style= {{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style= {{  backgroundColor: 'transparent'}} >
                        <img style={{ height: 50 , marginTop: 20}} src={require("./ArquiUrban_Categoria_512.png")} />
                    </div>
                    
                    <div style= {{  }}>
                        <a style={{ fontSize: 15, color: '#efb941', fontFamily: 'BikoRegular'  }}>ARQUITECTURA Y URBANISMO</a>
                    </div>
                </div>

                <div style= {{  backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style= {{  backgroundColor: 'transparent'}} >
                        <img style={{ height: 50 , marginTop: 20 }} src={require("./FloraFauna_Categoria_512.png")} />
                    </div>
                    
                    <div style= {{  }}>
                        <a style={{ fontSize: 15, color: '#54d186', fontFamily: 'BikoRegular' }}>FLORA Y FAUNA</a>
                    </div>
                </div>    

                <div style= {{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style= {{  backgroundColor: 'transparent'}} >
                        <img style={{ height: 50 , marginTop: 20 }} src={require("./Experimental_Categoria_512.png")} />
                    </div>
                    
                    <div style= {{  }}>
                        <a style={{ fontSize: 15, color: '#d63eaf', fontFamily: 'BikoRegular'  }}>EXPERIMENTAL</a>
                    </div>
                </div>
            
            </div>

        </div>
		}

        return (
            <div id="catalogue_section" style={{ marginTop: 0, backgroundColor: '#303030' }}>
                {mobileVar}
                <div style={{ height: 3, backgroundColor: 'white', width: '90%', marginRight: 'auto', marginLeft: 'auto', marginTop: 50, marginBottom: 50 }} />
                <Container>
                    <Row>
                        {this.state.toursCollection.map(tour => {
                            console.log(tour.id)
                            if(tour.data.published == null || tour.data.published == true){
                                return (
                                    <Col>                                    
                                        <VSphereItem title={tour.data.name} data={tour.data}  id={tour.id}/>                                    
                                    </Col>
                                );
                            }
                        })
                        }
                    </Row>
                </Container>
                <div style={{ height: 100 }} />
            </div>
        )
    }
}