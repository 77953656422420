import React from 'react';

import VInfoModal from '../InfoModal/InfoModal.js';

import {
    Link
} from "react-router-dom";

export default class VNomineeItem extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }


    render() {
        console.log(this.props.image)
        var bg=require('./Titulo1_Vota_512.png')
        return (
            <div >
                
                <div  style={{width: 250, marginTop: 0, marginLeft:'auto', marginRight:'auto', flexDirection: "row",  backgroundImage: "url("+bg+")" , backgroundSize: 'cover' }}>
                    <p style={{fontSize: 25, color: 'white', marginBottom: 0, fontFamily: 'BikoBold'}}> {this.props.title} </p>
                </div>
                    <img style={{maxWidth: 300}} src={this.props.image}alt="Third slide"/>                   
                    
               <div style={{flex: 1, flexDirection: "row", justifyContent: 'flex-start'}} >   
                   <div  style={{pointerEvents: 'none', borderRadius: '5px', width: 300, height:30, marginTop: 5, marginLeft:'auto', marginRight:'auto', flexDirection: "row",  backgroundColor: '#4f4f4f' }}>                        
                        <div  style={{pointerEvents: 'none', borderRadius: '5px', width: 300*this.props.percentage, height:30 , position: 'relative',  backgroundColor: '#ea2f64' }}/>
                        <p style={{fontSize: 20, color: 'white', position: 'relative', top:-30, marginBottom: 0, fontFamily: 'BikoBold'}}> {this.props.votes} votos. </p>                        
                   </div>
                    {  this.props.upVote &&
                        <Link onClick ={()=>this.props.onUpVote(this.props.id)}>
                            <img onClick ={()=>this.props.onUpVote(this.props.id)} style={{ maxHeight: 35}} src={require("./Boton_Like_64.png")}alt="Third slide"/>                                        
                        </Link>
                    }
                    {  this.props.downVote &&
                        <Link onClick ={()=>this.props.onDownVote(this.props.id)}>
                            <img style={{ maxHeight: 35}} src={require("./Boton_Dislike_64.png")}alt="Third slide"/>                    
                        </Link>
                    } 
                    { this.props.description!=null &&
                        <div style={{}}>
                            <VInfoModal title={this.props.title} description={this.props.description} img={this.props.image}/>
                        </div>
                    } 
                </div>                                                   
            </div>
        )
    }
}