import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    Link
} from "react-router-dom";

export default class VFooter extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {            
        }        
    }


    render() {        
        return (
            <div style={{width: "100%", marginTop: 0, flexDirection: "column",  backgroundColor: '#303030'}}>
                <div style={{width: "100%", flexDirection: "row", backgroundColor: 'transparent' }}>
                    <Container style={{marginTop: 0}}>
                        <Row >                    
                            <Col>
                                <a href={"https://www.patreon.com/virtualizalo"}>
                                    <img style={{ marginTop: 50, maxHeight: 75, marginLeft:'auto', marginRight: 'auto'}} src={require("./Patreon2_Support_512_esp.png")}/>
                                </a>
                            </Col>                    
                            <Col>
                                <a href={"/"}>
                                    <img style={{ marginTop: 50, maxHeight: 75, marginLeft:'auto', marginRight: 'auto'}} src={require("./LogoVirtualizalo_Footer_512.png")}/>
                                </a>
                            </Col>                    
                            <Col>
                                <a href={"https://gc-virtual.com"}>
                                    <img style={{marginTop: 50, maxHeight: 75, marginLeft:'auto', marginRight: 'auto'}} src={require("./GCVirtual_Footer_128.png")}/>
                                </a>
                            </Col> 
                            <Col style={{ backgroundColor: 'transparent', display: 'flex' , flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
                                <a href={"https://www.youtube.com/channel/UCgjlux1aJR-MPPxNyi_b_Gw"}>
                                    <img style={{ maxHeight: 50, marginLeft: 5, marginRight: 5}} src={require("./IconoBlanco_Youtube_64.png")}/>
                                </a>
                                <a href={"//twitter.com/VirtualizaloMX"}>
                                    <img style={{ maxHeight: 50, marginLeft: 5, marginRight: 5}} src={require("./IconoBlanco_Twitter_64.png")}/>
                                </a>
                                <a href={"//www.facebook.com/Virtual%C3%ADzalo-108564107513762/"}>
                                    <img style={{ maxHeight: 50, marginLeft: 5, marginRight: 5}} src={require("./IconoBlanco_Facebook_64.png")}/>
                                </a>
                                <a href={"https://www.instagram.com/virtualizalomx/"}>
                                    <img style={{ maxHeight: 50, marginLeft: 5, marginRight: 5}} src={require("./IconoBlanco_Instagram_64.png")}/>
                                </a>
                            </Col>                                               
                        </Row>
                    </Container>                 
                    <div style={{width: "90%", height: 3, marginTop: 50, marginBottom: 50, flexDirection: "row", backgroundColor: 'white', marginLeft:'auto', marginRight: 'auto'}} />                    
                    <div style={{width: "60%", flexDirection: "column", display:'flex', backgroundColor: 'transparent', marginLeft:'auto', marginRight: 'auto'}}>                        
                        <a style={{textAlign: 'center', fontSize: 20, textJustify: 'center', display: 'flex', marginLeft:'auto', marginRight: 'auto', marginBottom: 20, color: "white", fontFamily: 'BikoRegular'}}
                                    href='https://firebasestorage.googleapis.com/v0/b/virtualizalo-gc.appspot.com/o/public%2FT%C3%A9rminos%20de%20Uso.pdf?alt=media&token=0009ddac-8b00-4db4-90c4-1d8372dbc20b'>
                                    Términos y condiciones.<br/>
                        </a>
                        <p style={{fontSize: 13, color: "white", fontFamily: 'BikoRegular'}}>VIRTUALÍZALO y GC VIRTUAL, así como su logos, son propiedad de GameCoder Sudios, 2020.<br/>-</p>
                    </div> 
                </div>               
            </div>
        )
    }
}